/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    disc: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15A7 7 0 118 1a7 7 0 010 14m0 1A8 8 0 108 0a8 8 0 000 16"/><path pid="1" d="M10 8a2 2 0 11-4 0 2 2 0 014 0M8 4a4 4 0 00-4 4 .5.5 0 01-1 0 5 5 0 015-5 .5.5 0 010 1m4.5 3.5a.5.5 0 01.5.5 5 5 0 01-5 5 .5.5 0 010-1 4 4 0 004-4 .5.5 0 01.5-.5"/>',
    },
});
